import React from "react";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

class Shipping extends React.Component {
  render() {
    return (
      <div className="termsAndConditions">
        <Helmet>
          <title>Pravila zaštite podataka i privatnosti</title>
        </Helmet>
        <Container>
          <Link className="terms" to="/">
            Početna
          </Link>
          <h1 className="pageTitle">Pravila o slanju proizvoda</h1>
          <p>
            ​Po spremnosti robe za dostavu odnosno preuzimanje Trgovac Kupcu
            putem elektroničke pošte dostavlja obavijest o spremnosti na
            isporuku proizvoda. <br /> Dostava kupljenog proizvoda vrši se na
            adresu dostave navedenu prilikom izrade narudžbe. Dostava proizvoda
            Kupcu obavlja se isključivo radnim danom. U slučaju da neki od
            naručenih proizvoda nedostaje, Trgovac će ga besplatno dostaviti
            naknadno u najkraćem mogućem roku. <br /> Dostavu robe vrše
            djelatnici tvrtke GLS. Rok isporuke dostupne robe u e-trgovini je od
            3 do 14 radnih dana, osim proizvoda za koje je kupac obaviješten o
            drugačijem roku isporuke.
            <br /> Rok za dostavu počinje teći nakon zaprimanja zaprimanja
            narudžbe odnosno nakon što kupac dobije potvrdu o zaprimanju njegove
            narudžbe. Subote, nedjelje, blagdani i neradni dani ne računaju se u
            rok dostave. <br /> U slučaju da kupac odbije primiti ispravnu i
            neoštećenu robu koju je naručio, Trgovac ima pravo uskratiti od
            uplaćenog iznosa kupoprodajne cijene iznos izravnih troškova vezanih
            uz isporuku.
          </p>
        </Container>
      </div>
    );
  }
}

export default Shipping;
